import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` hosted our fourth demo night in San Francisco on Wednesday.`}</p>
    <p>{`Once again it was a nice way to learn more about what people are working on as well as network with the bot community.`}</p>
    <h2><a parentName="h2" {...{
        "href": "http://dashbot.io"
      }}>{`Dashbot`}</a>{` Broadcast Messaging`}</h2>
    <p>{`Dennis, our co-founder, demoed our latest feature — broadcast messaging.`}</p>
    <p>{`With our broadcast messaging feature, you can send messages to your users, targeted based on the analytics. This is a great way to re-engage your users and bring them back to your bot.`}</p>
    <p>{`It currently works for Facebook, Slack, and Kik. We’ll continue to build on the functionality for additional audience segmentation, scheduling, and triggers too.`}</p>
    <h2>{`Demos`}</h2>
    <p>{`If you would like to try it, `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/contact"
      }}>{`let us know`}</a>{`.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152927/0_TIb96kiXquNGu2PY.png)
    </figure>
[**Gyant**](http://www.gyant.com/)
    <p>{`First up was `}<a parentName="p" {...{
        "href": "http://m.me/thegyant"
      }}>{`Gyant`}</a>{` — a health robot. Stefan and Pascal demoed the bot and showed how it asks users questions about symptoms to identify what may be the issue, or provide peace of mind that you don’t have a particular issue. This is a great idea for a bot! They have heavy international usage as well, especially in Brazil, in Portuguese.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152927/0_no8zLb3B5pee7r1c.png)
    </figure>
[**Gupshup**](http://www.gupshup.io/)
    <p>{`Cecily from Gupshup demoed their visual bot building platform. They have thousands of developers building bots with Gupshup!`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152926/0_9n04BBRdb9n2R7gt.png)
    </figure>
[**Mobile Tonic**](http://www.mobile-tonic.com/)
    <p>{`We had a special treat with a visitor from out of town, Sebastian of Mobile Tonic. Sebastian and his team build bots in Argentina, including `}<a parentName="p" {...{
        "href": "http://m.me/Scrum.com.ar"
      }}>{`ESPN’s Scrum`}</a>{` bot, primarily on Facebook and Telegram. He showed a brief demo of their bot platform as well. It was interesting to learn more about what’s happening outside the US.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152926/0_AJh0cdHnAp4tuNJ_.png)
    </figure>
[**Polly**](http://polly.datalog.ai/)
    <p>{`Jack and his team from Datalog.ai demoed their Polly bot — a conversational bot built on their platform. It was a fun bot to demo as it can carry on conversations in multiple languages — members of the audience tried it out with Spanish and German in the same thread and the bot carried the conversation. It can also detect the content of images.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152926/0_vrzTsKQItlXOzizb.png)
    </figure>
[**Rocket Fuel**](http://www.rocketfuel.com/)
    <p>{`Nik demoed Rocketfuel’s bot for interacting with their DSP to run queries for stats. It’s currently only for Rocketfuel employees but they have plans to open to customers too.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152925/0_f09VvMLFpLp54Fp6.png)
    </figure>
[**Rushtix**](http://www.rushtix.com/)
    <p>{`Jill demoed Rushtix bot for finding tickets to events. This is an interesting service wherein you can sign up for membership to get tickets to local events.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152925/0_lCbFwpx1en5zNHAX.png)
    </figure>
[**Swell**](http://www.swell.wtf/)
    <p>{`Joe demoed the `}<a parentName="p" {...{
        "href": "http://m.me/swell.bot"
      }}>{`Swelly`}</a>{` bot which is doing very well — they have incredible growth and retention — nearly a million users and one of the top bots on Facebook. The bot enables users to create and vote on “swells” — quick polls — which can be pretty entertaining!`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/12/07152924/0_o7aZ1IQ6Jnhy5OPE.png)
    </figure>
[**Trulia**](http://www.trulia.com/)
    <p>{`Alex and Corina demoed `}<a parentName="p" {...{
        "href": "http://m.me/trulia"
      }}>{`Trulia’s bot`}</a>{` which enables users to find apartments to rent. It works pretty well. It’s kind of fun to test it in SF and see what you can get for different dollar amounts!`}</p>
    <h3>{`Next Demo Night`}</h3>
    <p>{`Our next demo night will be December 6th in SF. If you’re interested in demoing, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/contact"
      }}>{`let us know`}</a>{`.`}</p>
    <h3>{`Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables bot developers and enterprises to increase user acquisition, engagement, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we have bot specific processing and metrics like conversational analytics, sentiment analysis, and the full chat session transcripts.`}</p>
    <p>{`More importantly, we provide tools that make use of the data to improve acquisition and engagement, like live person take over of chat sessions and push notifications for re-engagement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      